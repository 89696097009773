*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body, #root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

.dSK7zq_boxContainer {
  padding: 1px;
  display: flex;
}

.dSK7zq_box {
  cursor: pointer;
  border: 1px solid #e0e0e0;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 3px;
  display: flex;
}

.dSK7zq_unselected {
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px #0000001a;
}

.dSK7zq_selected {
  color: #fff;
  background-color: #007ee5;
  box-shadow: 0 4px 6px #0003;
}

.dSK7zq_header {
  color: #333;
  margin-top: 20px;
  margin-bottom: 1px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.dSK7zq_headerError {
  color: #db3e00;
  animation: 1s infinite dSK7zq_pulse;
}

@keyframes dSK7zq_pulse {
  0% {
    box-shadow: 0 0 #db3e0066;
  }

  70% {
    box-shadow: 0 0 0 10px #db3e0000;
  }

  100% {
    box-shadow: 0 0 #db3e0000;
  }
}

.dSK7zq_notificationBox {
  color: #333;
  background-color: #feefc3;
  border: 1px solid #fed7b0;
  border-radius: 5px;
  margin-top: 5px;
  padding: 10px;
  font-size: 14px;
  display: none;
}

.dSK7zq_notificationBoxShow {
  display: block;
}

.BAWgDq_submitButton {
  color: #fff;
  cursor: pointer;
  background-color: #0052cc;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 20px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 18px;
  transition: background-color .3s;
  box-shadow: 0 2px 4px #0000001a;
}

.BAWgDq_submitButton:hover {
  background-color: #003ea0;
}

.BAWgDq_surveyContainer {
  margin-top: 20px;
  margin-bottom: 120px;
  margin-left: 55px;
}

.BAWgDq_header {
  text-align: center;
  color: #f2f2f2;
  background-color: #007ee5;
  border: 1px solid #f2f2f2;
  max-width: 400px;
  padding: 0;
  font-size: 2em;
  transition: all .3s;
}

.BAWgDq_header:hover {
  color: #f2f2f2;
  background-color: #007ee5;
  border: 1px solid #f2f2f2;
}

/*# sourceMappingURL=index.ffd845e7.css.map */
